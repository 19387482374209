import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const ProjectsPageTemplate = ({
  projects,
  title,
  helmet
}) => {
  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div >
          <div >
            <h1 >
              {title}
            </h1>
            <div>
              <ul id='projects-list'>
                {projects.map(project => {
                  return (
                    <a className='box' key={project.name} href={project.link}>
                      <li className='projects-item' >
                        <PreviewCompatibleImage
                          imageInfo={{
                            style: {maxWidth: '200px'},
                            image: project.image,
                            alt: `Image for Project todo better alt sigh lol`,
                          }}
                        />
                        <h1>
                          {project.name}
                        </h1>
                      </li>
                    </a>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ProjectsPageTemplate.propTypes = {
  projects: PropTypes.array.isRequired,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const ProjectsPage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout activePage="projects">
      <ProjectsPageTemplate
        projects={page.frontmatter.projects}
        helmet={
          <Helmet titleTemplate="%s | Projects">
            <title>{`SM28 | Projects`}</title>
            {/*  TODO SEO DESCRIPTION
            <meta
              name="description"
              content={`${page.frontmatter.description}`}
            /> */}
          </Helmet>
        }
        title='Projects'
      />
    </Layout>
  )
}

ProjectsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default ProjectsPage

export const pageQuery = graphql`
  query ProjectsPage($id: String!){
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        projects{
            name
            description
            link
            image {
            childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
        }
      }
    }
  }
`
